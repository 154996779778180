
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import Overview from "@/view/pages/resources/documentation/base/overlay/Overview.vue";
import BasicExample from "@/view/pages/resources/documentation/base/overlay/BasicExample.vue";
import ContentBlocking from "@/view/pages/resources/documentation/base/overlay/ContentBlocking.vue";
import CardBlocking from "@/view/pages/resources/documentation/base/overlay/CardBlocking.vue";
import ModalBlocking from "@/view/pages/resources/documentation/base/overlay/ModalBlocking.vue";

export default defineComponent({
  name: "overlay",
  components: {
    Overview,
    BasicExample,
    ContentBlocking,
    CardBlocking,
    ModalBlocking,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Overlay");
    });
  },
});
